import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: ''
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "index-page" */ "../views/index.vue"),
  },
  {
    path: '/applyment/products',
    name: 'ApplymentProductList',
    meta: {
      title: '产品列表'
    },
    component: () => import(/* webpackChunkName: 'applyment' */ '@/views/Applyment/ApplymentProductList.vue'),
  },
  {
    path: '/applyment/products/:productId',
    name: 'ApplymentProductDetail',
    meta: {
      title: '产品详情'
    },
    component: () => import(/* webpackChunkName: 'applyment' */ '@/views/Applyment/ApplymentProductDetail.vue'),
  },
  {
    path: '/userAffiliate',
    name: 'UserAffiliate',
    meta: {
      title: '产品列表'
    },
    component: () => import(/* webpackChunkName: 'UserAffiliate' */ '@/views/UserAffiliate/list.vue'),
  },
  {
    path: '/userAffiliate/detail',
    name: 'UserAffiliateQrCode',
    meta: {
      title: '产品详情'
    },
    component: () => import(/* webpackChunkName: 'UserAffiliate' */ '@/views/UserAffiliate/detail.vue'),
  },
  {
    path: '/userAffiliate/procedure',
    name: 'UserAffiliateProcedure',
    meta: {
      title: '图文流程'
    },
    component: () => import(/* webpackChunkName: 'UserAffiliate' */ '@/views/UserAffiliate/procedure.vue'),
  },
  {
    path: '/applymentRecord',
    name: 'ApplymentRecord',
    meta: {
      title: '申请记录',
    },
    component: () => import(/* webpackChunkName: 'ApplymentRecord' */ '@/views/ApplymentRecord/list.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
      //判断是否有标题
      document.title = to.meta.title
  } else {
      document.title = ''
  }
  next()
})

export default router;
