import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Cell, CellGroup, Button, NavBar, Form, Field, Row, Col, List, PullRefresh, Search, Tab, Tabs, Image } from 'vant'
import VueClipboard from 'vue3-clipboard'
import { vLongpress } from '@nanogiants/vue3-longpress';


const app = createApp(App)
app.use(store)
app.use(router)
app
  .use(Cell).use(CellGroup)
  .use(Form).use(Field)
  .use(Button)
  .use(NavBar)
  .use(Row).use(Col)
  .use(List)
  .use(PullRefresh)
  .use(Search)
  .use(Tabs).use(Tab)
  .use(Image)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})
app.directive('use-longpress', vLongpress);
app.directive('title', {
  // eslint-disable-next-line no-unused-vars
  created: function (el, binding) {
    document.title = el.dataset.title
  }
})
// eslint-disable-next-line no-unused-vars
const vm = app.mount('#app')
